import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const generateTokens = httpsCallable(functions, "generateTokens");

export default function AuthorizePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [authorized, setAuthorized] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function saveRefreshToken() {
      const code = searchParams.get("code");
      try {
        const tokens = await generateTokens({
          code: code,
          uid: currentUser.uid,
          env: process.env.NODE_ENV,
        });
        setAuthorized(true);
      } catch (error) {
        console.log(error);
      }
    }
    saveRefreshToken();
  }, []);

  useEffect(() => {
    if (authorized) {
      navigate("/admin"); // Redirect to the admin page
    }
  }, [authorized, navigate]);

  return (
    <div>
      <Box>Authorization Page</Box>
    </div>
  );
}
