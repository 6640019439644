import { Stack, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import ClientHeader from "./ClientHeader";

// main layout applied to every client page
export default function ClientLayout() {
  return (
    <>
      {/* <Flex direction="column" margin="auto" width="85%"> */}
      {/* <ClientHeader /> */}
      <Outlet />
      {/* </Flex> */}
    </>
  );
}
