import { useEffect, useState } from "react";
import { Box, Button, Flex, Stack } from "@chakra-ui/react";
import { useAuth } from "../contexts/AuthContext";
import EventRequestList from "./EventRequestList";
import EventList from "./EventList";

export default function EventBackground() {
  const { logout } = useAuth();
  const [eventType, setEventType] = useState("requests");

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100vh"
      spacing="1rem"
    >
      <Flex gap="2rem">
        <Button
          onClick={() => {
            setEventType("requests");
          }}
          colorScheme="blue"
        >
          Event Requests
        </Button>
        <Button
          onClick={() => {
            setEventType("events");
          }}
          col
          colorScheme="green"
        >
          Scheduled Events
        </Button>
      </Flex>

      <Box
        height="70vh"
        width="50%"
        rounded="2xl"
        border="0.05rem gray solid"
        padding="2rem"
        bgColor="gray.100"
        overflowY="scroll"
      >
        {eventType == "requests" ? <EventRequestList /> : <EventList />}
      </Box>

      <Button colorScheme="red" onClick={logout} width="5rem" height="3rem">
        Sign Out
      </Button>
    </Stack>
  );
}
