import EventBackground from "../components/EventBackground";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { useEffect } from "react";
import { isAuthorized } from "../DataController";
import { useAuth } from "../contexts/AuthContext";
// get link to sign in to google account
const getGoogleAuthLink = httpsCallable(functions, "getGoogleAuthLink");
const getEvents = httpsCallable(functions, "getEvents");

//Add a check to the actual calendar to see if the refresh token is still working, if not, reauthroize
export default function AdminHomePage() {
  const { currentUser } = useAuth();

  async function redirectToAuthorization() {
    console.log("generating link");
    const urlObject = await getGoogleAuthLink(process.env.NODE_ENV);
    window.location.href = urlObject.data;
  }

  useEffect(() => {
    async function checkAuthorization() {
      const authorized = await isAuthorized(currentUser.uid);

      if (!authorized) {
        redirectToAuthorization();
      }
      // const events = await getEvents(currentUser.uid);
      //console.log(events);
    }
    checkAuthorization();
  }, []);
  return <EventBackground />;
}
