import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useFormik } from "formik";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Link } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";

export default function Login() {
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef(null);

  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({
        preventScroll: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values, actions) => {
      setLoading(true);
      console.log(values);
      try {
        await login(values.email, values.password);
      } catch (error) {
        console.log("failed to log in");
        console.log(error);
        setError("Failed to log in");
      }
      setLoading(false);
      actions.resetForm();
    },
  });

  return (
    <Stack
      spacing="8"
      backgroundColor="white"
      width="30%"
      height="35rem"
      boxShadow="2xl"
    >
      {/* <Link to="/">
        <Button bgColor="inherit">
          <ArrowBackIcon boxSize={7} />
        </Button>
      </Link> */}
      <Stack spacing="6">
        {/* <Logo /> */}
        <Stack
          spacing={{
            base: "2",
            md: "3",
          }}
          textAlign="center"
        >
          <Heading
            fontSize="2xl"
            marginTop="2rem"
            size={useBreakpointValue({
              base: "xs",
              md: "sm",
            })}
          >
            Log in to your account
          </Heading>
          <HStack spacing="1" justify="center"></HStack>
        </Stack>
      </Stack>
      <Box
        py={{
          base: "0",
          sm: "8",
        }}
        px={{
          base: "4",
          sm: "10",
        }}
        bg={useBreakpointValue({
          base: "transparent",
          sm: "bg-surface",
        })}
      >
        <Stack as="form" spacing="6" onSubmit={formik.handleSubmit}>
          {error && (
            <Alert marginTop="10px" status="error">
              <AlertIcon />
              <AlertTitle m fontSize="xs">
                {error}
              </AlertTitle>
              {/* <AlertDescription>Your Chakra experience may be degraded.</AlertDescription> */}
            </Alert>
          )}

          <Stack spacing="5">
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <InputGroup>
                <InputRightElement>
                  <IconButton
                    variant="link"
                    aria-label={isOpen ? "Mask password" : "Reveal password"}
                    icon={isOpen ? <HiEyeOff /> : <HiEye />}
                    onClick={onClickReveal}
                  />
                </InputRightElement>
                <Input
                  id="password"
                  //ref={mergeRef}
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  type={isOpen ? "text" : "password"}
                  autoComplete="current-password"
                  required
                />
              </InputGroup>
            </FormControl>
          </Stack>
          <HStack justify="space-between">
            <Checkbox defaultChecked colorScheme="purple">
              Remember me
            </Checkbox>
            <Button variant="link" colorScheme="purple" size="sm">
              Forgot password?
            </Button>
          </HStack>
          <Stack spacing="6">
            <Button
              isDisabled={loading}
              isLoading={loading}
              colorScheme="purple"
              type="submit"
            >
              Sign in
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
