import React from "react";
import "../styles/Calendar.css";
import {
  Flex,
  Box,
  Button,
  useDisclosure,
  Input,
  Image,
  Heading,
  Text,
} from "@chakra-ui/react";

import ClientHeader from "./ClientHeader";
import LandingBanner from "../images/LandingBanner.JPG";
import EventModal from "./EventModal";

export default function Landing() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box position="relative" maxHeight="80vh" overflow="hidden">
        <ClientHeader />
        <Box
          bgColor="rgba(20, 20, 20, 0.6)"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex={-1}
        />
        <Image
          src={LandingBanner}
          position="absolute"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          zIndex={-2} // To place the image behind the content
        />

        <Flex
          direction="column"
          justifyContent="center"
          alignItems="Center"
          height="70vh"
          gap="3rem"
          zIndex={3}
          paddingTop="3rem"
        >
          <Heading
            fontSize={{ base: "2rem", sm: "4rem", lg: "8rem" }}
            color="white"
          >
            DJ Romeo
          </Heading>
          {/* Add a section for DJ's bio */}
          <Text fontSize="2xl" color="white" maxW="600px" textAlign="center">
            Welcome to DJ Romeo's world of music! With over 10 years of
            experience...
          </Text>
          {/* ... */}

          <Button
            h={{ base: "3rem", md: "5rem", lg: "5rem" }}
            w={{ base: "8rem", md: "10.6rem", lg: "12.2rem" }}
            minHeight="5rem"
            fontSize={{ base: "xl", md: "3xl", lg: "4xl" }}
            onClick={onOpen}
            bgColor="goldenrod"
            fontWeight={"bold"}
            _hover={{ bg: "#ae8419" }}
            mb="5rem"
            color="white"
          >
            Book Now
          </Button>
          <EventModal isOpen={isOpen} onClose={onClose} />
        </Flex>
      </Box>
    </>
  );
}
