import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { Flex } from "@chakra-ui/react";
import Event from "./Event";
import { useAuth } from "../contexts/AuthContext";

export default function EventList() {
  const [eventList, setEventList] = useState([]);
  const getEvents = httpsCallable(functions, "getEvents");
  const { currentUser } = useAuth();

  useEffect(() => {
    async function displayEvents() {
      const data = { env: process.env.NODE_ENV, uid: currentUser.uid };
      const result = await getEvents(data);
      setEventList(result.data);
    }

    displayEvents();
  }, []);

  return (
    <Flex gap="2rem" direction="column" width="100%">
      {eventList &&
        eventList.map((event) => <Event key={event.id} eventInfo={event} />)}
    </Flex>
  );
}
