import { db } from "./firebase";
import {
  addDoc,
  collection,
  query,
  orderBy,
  getDocs,
  doc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";

export async function createEventRequest(eventInfo) {
  try {
    const docRef = await addDoc(collection(db, "eventRequests"), eventInfo);
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

export async function getEventRequests() {
  const q = query(collection(db, "eventRequests"), orderBy("startDateTime"));
  const querySnapshot = await getDocs(q);
  const list = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data(); // doc.data() is never undefined for query doc snapshots
    data.id = doc.id;
    list.push(data);
  });

  console.log(list);

  return list;
}

const createEvent = httpsCallable(functions, "createEvent");

export async function acceptEventRequest(eventInfo, uid) {
  const eventData = {
    summary: eventInfo.summary,
    //location: "800 Howard St., San Francisco, CA 94103",
    description: eventInfo.description,
    start: {
      dateTime: eventInfo.startDateTime.toDate(),
      timeZone: "America/New_York",
    },
    end: {
      dateTime: eventInfo.endDateTime.toDate(),
      timeZone: "America/New_York",
    },
    //attendees: [{ email: "lpage@example.com" }, { email: "sbrin@example.com" }],
    reminders: {
      useDefault: false,
      overrides: [
        { method: "email", minutes: 24 * 60 },
        { method: "popup", minutes: 60 },
      ],
    },
  };

  console.log(eventData);
  // add the event to your calendar
  const env = process.env.NODE_ENV;
  createEvent({ eventData, uid, env });

  //delete the request from the database
  await deleteDoc(doc(db, "eventRequests", eventInfo.id));
}

export async function denyEventRequest(eventRequestID) {
  await deleteDoc(doc(db, "eventRequests", eventRequestID));
}

export async function isAuthorized(uid) {
  const docRef = doc(db, "Admin", uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    return true;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    return false;
  }
}
