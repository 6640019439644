import { Flex, Box, Image } from "@chakra-ui/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CouplePic1 from "../images/Couple1.JPG";
import CouplePic2 from "../images/Couple2.JPG";
import CouplePic3 from "../images/Couple3.JPG";
import SoloPic1 from "../images/Solo1.JPG";
export default function About() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={true}
      responsive={responsive}
      ssr={false} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      // customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      <div>
        <Image src={CouplePic1} />
      </div>
      <div>
        <Image src={CouplePic2} />
      </div>

      <div>
        <Image src={CouplePic3} />
      </div>
      <div>
        <Image src={SoloPic1} />
      </div>
    </Carousel>
  );
}
