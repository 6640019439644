import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Stack,
  Textarea,
  Heading,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { createEventRequest } from "../DataController";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

// Add some checks to validate the form before creating an object
// Otherwise the calendar event might fail when romeo accepts the request
// Make sure end dateTime is after the start dateTime

export default function EventModal({ isOpen, onClose }) {
  const sendEmail = httpsCallable(functions, "sendEmail");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      startDateTime: "",
      endDateTime: "",
      description: "",
      summary: "",
    },
    validate: (values) => {
      const errors = {};

      // Custom validation for startDateTime and endDateTime
      const startSeconds = new Date(values.startDateTime).getTime();
      const endSeconds = new Date(values.endDateTime).getTime();
      const currentSeconds = new Date().getTime();

      if (endSeconds <= startSeconds) {
        errors.endDateTime = "End time must be after start time";
        errors.startDateTime = "Start time must be before end time";
      }

      if (startSeconds <= currentSeconds) {
        errors.startDateTime =
          "The event must start at some point in the future";
      }

      return errors;
    },
    onSubmit: async (values, actions) => {
      setLoading(true);
      console.log(values);

      // convert date string to date objects
      values.startDateTime = new Date(values.startDateTime);
      values.endDateTime = new Date(values.endDateTime);

      await createEventRequest(values);
      await sendEmail(values);
      setLoading(false);
      // reset form and close modal
      actions.resetForm({
        values: {
          firstName: "",
          lastName: "",
          email: "",
          startDateTime: "",
          endDateTime: "",
          description: "",
          summary: "",
        },
      });

      onClose();
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading textAlign={"center"}> Request an Event</Heading>{" "}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack
            as="form"
            onSubmit={formik.handleSubmit}
            margin="auto"
            gap="1rem"
          >
            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                name="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                bgColor="white"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                name="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                bgColor="white"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Event Title</FormLabel>
              <Input
                name="summary"
                onChange={formik.handleChange}
                value={formik.values.summary}
                bgColor="white"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel> Email </FormLabel>
              <Input
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                bgColor="white"
                type="email"
              />
            </FormControl>

            <FormControl
              isRequired
              isInvalid={
                formik.touched.startDateTime && formik.errors.startDateTime
              }
            >
              <FormLabel> Start Time </FormLabel>
              <Input
                type="datetime-local"
                name="startDateTime"
                onChange={formik.handleChange}
                value={formik.values.startDateTime}
                bgColor="white"
              />
              <FormErrorMessage>{formik.errors.startDateTime}</FormErrorMessage>
            </FormControl>

            <FormControl
              isRequired
              isInvalid={
                formik.touched.endDateTime && formik.errors.endDateTime
              }
            >
              <FormLabel> End Time </FormLabel>
              <Input
                type="datetime-local"
                name="endDateTime"
                onChange={formik.handleChange}
                value={formik.values.endDateTime}
                bgColor="white"
              />
              <FormErrorMessage>{formik.errors.endDateTime}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired>
              <FormLabel> Describe the Event </FormLabel>
              <Textarea
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                bgColor="white"
              />
            </FormControl>
            <ModalFooter>
              <Flex justifyContent="center" width="100%">
                <Button
                  colorScheme="yellow"
                  type="submit"
                  size="lg"
                  isLoading={loading}
                  isDisabled={loading}
                >
                  Submit
                </Button>
              </Flex>
            </ModalFooter>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
