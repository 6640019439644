import LandingPage from "./routes/LandingPage";
import AdminHomePage from "./routes/AdminHomePage";
import AdminRoutes from "./routes/AdminRoutes";
import ClientRoutes from "./routes/ClientRoutes";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "./routes/PageNotFound";
import AdminLayout from "./components/AdminLayout";
import ClientLayout from "./components/ClientLayout";
import LoginPage from "./routes/LoginPage";
import AuthorizePage from "./routes/AuthorizePage";
import { AuthProvider } from "./contexts/AuthContext";
import { Box } from "@chakra-ui/react";

function App() {
  return (
    <Box>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<ClientRoutes />}>
            <Route path="" element={<ClientLayout />}>
              <Route path="" element={<LandingPage />} />
              <Route path="login" element={<LoginPage />} />
            </Route>
          </Route>

          <Route path="/" element={<AdminRoutes />}>
            <Route path="admin" element={<AdminLayout />}>
              <Route path="" element={<AdminHomePage />} />
              <Route path="authorize" element={<AuthorizePage />} />
            </Route>
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AuthProvider>
    </Box>
  );
}

export default App;
