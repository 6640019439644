import { Flex, Button, Box } from "@chakra-ui/react";
import { acceptEventRequest, denyEventRequest } from "../DataController";
import { useAuth } from "../contexts/AuthContext";
import { useState } from "react";

export default function EventRequest({ eventInfo, eventList, setEventList }) {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  //console.log(eventInfo.startDateTime.toDate());
  async function AcceptRequest() {
    setLoading(true);
    console.log("accepting");
    await acceptEventRequest(eventInfo, currentUser.uid);
    setEventList(eventList.filter((event) => event.id !== eventInfo.id));
    setLoading(false);
  }

  async function DenyRequest() {
    console.log("denying request");
    setLoading(true);
    await denyEventRequest(eventInfo.id);
    setEventList(eventList.filter((event) => event.id !== eventInfo.id));
    setLoading(false);
  }

  return (
    <Flex
      bgColor="white"
      height="4rem"
      width="100%"
      alignItems="center"
      padding="2rem"
    >
      <Box width="30rem">
        {eventInfo.summary + " - " + eventInfo.startDateTime.toDate()}
      </Box>
      <Button
        onClick={AcceptRequest}
        colorScheme="green"
        marginLeft="4rem"
        height="3rem"
        width="5rem"
        isLoading={loading}
        isDisabled={loading}
      >
        Accept
      </Button>

      <Button
        onClick={DenyRequest}
        colorScheme="red"
        marginLeft="4rem"
        height="3rem"
        width="5rem"
        isLoading={loading}
        isDisabled={loading}
      >
        Deny
      </Button>
    </Flex>
  );
}
