import Login from "../components/Login";
import { Flex } from "@chakra-ui/react";

export default function LoginPage() {
  return (
    <Flex alignItems="center" justifyContent="center" height="100vh">
      <Login />
    </Flex>
  );
}
