import Landing from "../components/Landing";
import ThreeTierPricing from "../components/ThreeTierPricing";
import LargeWithLogoCentered from "../components/LargeWithLogoCentered";
import About from "../components/About";

export default function LandingPage() {
  return (
    <>
      <Landing />
      <ThreeTierPricing />
      <About />
      <LargeWithLogoCentered />
    </>
  );
}
