import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { Flex, Button, Box } from "@chakra-ui/react";

export default function Event({ eventInfo }) {
  //const createEvent = httpsCallable(functions, "createEvent");
  //console.log(eventInfo.startDateTime.toDate());
  return (
    <Flex
      bgColor="white"
      height="4rem"
      width="100%"
      alignItems="center"
      padding="2rem"
    >
      <Box width="30rem">{eventInfo.summary}</Box>
      <Button colorScheme="red" marginLeft="4rem" height="3rem" width="5rem">
        Cancel
      </Button>
    </Flex>
  );
}
