import { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import EventRequest from "./EventRequest";
import { getEventRequests } from "../DataController";

export default function EventRequestList() {
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    async function displayEventRequests() {
      const result = await getEventRequests();
      setEventList(result);
    }

    displayEventRequests();
  }, []);

  return (
    <Flex gap="2rem" direction="column" width="100%">
      {eventList &&
        eventList.map((event) => (
          <EventRequest
            key={event.id}
            eventInfo={event}
            eventList={eventList}
            setEventList={setEventList}
          />
        ))}
    </Flex>
  );
}
