import { Stack, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

// main layout applied to every applicant page
export default function AdminLayout() {
  return (
    <Flex direction="column">
      {/* <ApplicantHeader /> */}
      <Outlet />
    </Flex>
  );
}
